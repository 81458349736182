import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AuthProvider from "./contexts/Auth";
import MarketplaceProvider from "./contexts/Marketplace";
import {createTheme} from "./theme";
import Authentication from "./components/Authentication";
import Admin from "./components/Admin";
import './App.css';
import Livechat from "./components/Livechat";
import Main from "./components/Main";
import AboutUs from "./components/AboutUs";
import Support from "./components/Support";
import Documentation from "./components/Livechat";

function App() {
  return (
    <AuthProvider>
      <MarketplaceProvider>
        <Router>
            <Switch>
                <Route exact path="/">
                    <Main />
                </Route>
                <Route exact path="/about-us">
                  <AboutUs />
                </Route>
                <Route exact path="/livechat">
                  <Livechat />
                </Route>
                <Route exact path="/admin">
                  <Admin />
                </Route>
                <Route exact path="/support">
                  <Support />
                </Route>
                <Route exact path="/documentation">
                  <Documentation />
                </Route>
                <Route exact path="/register">
                  <Authentication authState='signup' />
                </Route>
                <Route exact path="/login">
                  <Authentication />
                </Route>
            </Switch>
        </Router>
      </MarketplaceProvider>
    </AuthProvider>
  );
}

export default App;
