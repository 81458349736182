import React, {createContext, useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import {onAuthUIStateChange} from "@aws-amplify/ui-components";

export const AuthContext = createContext({});

export default function AuthProvider(props) {
  const children = props.children;
  const [user, setUser] = useState({});
  const [name, setName] = useState(null);
  const [groups, setGroups] = useState([]);
  const [authState, setAuthState] = useState({});

  const getUser = async () => {
    try {
      // will throw an exception if the user is not signed in
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      console.log(user)

      setUser(user)
      setName(user.attributes.name);
      setGroups([
        ...(user.signInUserSession.accessToken.payload["cognito:groups"] || []),
      ]);

    } catch(err) {
      console.log('Not signed in')
    }
  }

  useEffect(() => {
    if (!user?.attributes?.name) {
      void getUser()
    }
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, nextAuthData) => {
      setAuthState(nextAuthState);
      setUser(nextAuthData);
    });
  }, []);

  return (
      <AuthContext.Provider
          value={{
            user,
            authState,
            name,
            groups,
          }}
      >
          {children}
      </AuthContext.Provider>
  );
}
