import React, { createContext, useState } from "react"

import Marketplace from "../lib/Marketplace"
import MarketplaceService from "../lib/MarketplaceService"

export const MarketplaceContext = createContext(null)

export default function MarketplaceProvider(props) {
  const children = props.children;
  const [ marketplace ] = useState(new Marketplace(new MarketplaceService()))

  return (
    <MarketplaceContext.Provider value={marketplace}>
      {children}
    </MarketplaceContext.Provider>
  )
}
