import MarketplaceService from './MarketplaceService'
import {API} from "aws-amplify";

const parseResponse = async (response: Response) => {
  const text = await response.text();
  return JSON.parse(text || "{}")
}

export default class Marketplace {
  #service: MarketplaceService

  constructor(service: MarketplaceService) {
    this.#service = service
  }

  // async saveCustomer(queryParams, formData) {
  //     const response = await this.#service.saveCustomer(queryParams, formData)
  //     return await parseResponse(response)
  // }

  // async getCampaigns() {
  //   const response = await this.#service.getCampaigns()
  //   return await parseResponse(response)
  // }
  //
  // async getCampaign(id: string) {
  //   const response = await this.#service.getCampaign(id)
  //   return await parseResponse(response)
  // }
  //
  // async createCampaign(body: string) {
  //   const response = await this.#service.createCampaign(body)
  //   return await parseResponse(response)
  // }
  //
  // async deleteCampaign(id: string) {
  //   const response = await this.#service.deleteCampaign(id)
  //   return await parseResponse(response)
  // }
  //
  // async updateCampaign(id: string, body: string) {
  //   try {
  //     // No content is returned, success or error is based on status code
  //     await this.#service.updateCampaign(id, body)
  //     return true
  //   } catch (err) {
  //     return false
  //   }
  // }
  //
  // async getCampaignUploads(id: string) {
  //   const response = await this.#service.getCampaignUploads(id)
  //   return await parseResponse(response)
  // }
  //
  // async getCampaignCustomersDownloadFile(campaignId: string) {
  //   const response = await this.#service.getCampaignCustomersDownloadFile(campaignId)
  //   return await parseResponse(response)
  // }
  //
  // async getCampaignMetrics(campaignId: string) {
  //   const response = await this.#service.getCampaignMetrics(campaignId)
  //   if (response.status === 200) {
  //     return response.data
  //   }
  //   console.error('Failed to fetch the campaign metrics:', response)
  // }
}
