import React, {useContext} from "react";
import {makeStyles} from "@mui/styles";
import {AuthContext} from "../contexts/Auth";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Star from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  adminContainer: {

  },
  appBar: {
  },
  menuButton: {
  },
  sidePanel: {
    minWidth: '320px',
  },
  sidePanelHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '0 16px',
  },
  sidePanelHeaderLogo: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',

    '& img': {
      height: '25px',
      marginRight: theme.spacing(1.5),

    }
  },
  title: {
    flexGrow: 1,
  },
  titleLogo: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),

    '& img': {

    }
  },
  signInOutButton: {

  },
}))


const Header = () => {
  const classes = useStyles();
  const {user} = useContext(AuthContext);
  const loggedIn = user && user.username;
  const [sidePanelState, setSidePanelState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setSidePanelState(open);
  };

  const handleSignOutButtonClick = async (e) => {
    e.preventDefault();

    try {
      await Auth.signOut();

      Hub.dispatch('UI Auth', {   // channel must be 'UI Auth'
        event: 'AuthStateChange',    // event must be 'AuthStateChange'
        message: 'signedout'    // message must be 'signedout'
      });

    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  return (
    <>
      <Drawer anchor={'left'} open={sidePanelState} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          className={classes.sidePanel}
        >
          <div className={classes.sidePanelHeader}>
            <div className={classes.sidePanelHeaderLogo}>
              <img
                src="/images/digital-space-32x28.png"
                title="Digital Space Logo"
                alt="Digital Space Logo"
              />
              Digital Space
            </div>
            <div>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
                onClick={toggleDrawer(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <List>
            <ListItem button>
              <ListItemIcon><Star /></ListItemIcon>
              <Link to="/">
                <ListItemText primary="Home" />
              </Link>
            </ListItem>
            <ListItem button>
              <ListItemIcon><Star /></ListItemIcon>
              <Link to="/about-us">
                <ListItemText primary="About Us" />
              </Link>
            </ListItem>
            <ListItem button>
              <ListItemIcon><Star /></ListItemIcon>
              <Link to="/admin">
                <ListItemText primary="Admin" />
              </Link>
            </ListItem>
            <ListItem button>
              <ListItemIcon><Star /></ListItemIcon>
              <Link to="/livechat">
                <ListItemText primary="Livechat" />
              </Link>
            </ListItem>
            <ListItem button>
              <ListItemIcon><Star /></ListItemIcon>
              <Link to="/documentation">
                <ListItemText primary="Documentation" />
              </Link>
            </ListItem>
            <ListItem button>
              <ListItemIcon><Star /></ListItemIcon>
              <Link to="/support">
                <ListItemText primary="Support" />
              </Link>
            </ListItem>
          </List>
        </div>
      </Drawer>

      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>

          <div className={classes.titleLogo}>
            <img
              src="/images/digital-space-32x28.png"
              title="Digital Space Logo"
              alt="Digital Space Logo"
            />
          </div>

          <Typography variant="h6" className={classes.title}>
            Marketplace Admin Panel
          </Typography>

          <div className={classes.signInOutButton}>
            {loggedIn &&
              // <AmplifySignOut />
              <Button variant="contained" disableElevation onClick={handleSignOutButtonClick}>
                Sign out
              </Button>
            }

            {!loggedIn &&
              <Button component={Link} to="/login" variant="contained" disableElevation>
                Login
              </Button>
            }
          </div>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header;
