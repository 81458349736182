import React from "react";
import {makeStyles} from "@mui/styles";
import Header from "./Header";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

const useStyles = makeStyles((theme) => ({
  root: {

  },
}))

const AboutUs = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />

      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12}>
            <h1>
              Hello, we are Digital Space.
            </h1>
            <h2>
              We enable change for our customers through tailored digital
              solutions that improve workflow and accelerate growth.
            </h2>
            <h3>
              Connected Space
            </h3>
            <p>
              Strong leadership is vital to our success, which is why we have an award-winning leadership team.
            </p>
            <h3>
              Productive Space
            </h3>
            <p>
              We’re committed to helping your organisation empower employees and learners by integrating
              learning into every stage of your organisation, including across your business.
            </p>
            <h3>
              Secure Space
            </h3>
            <p>
              Every day, we help our customers create and deliver training that engages
              people, grows employee engagement and improves business performance.
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default AboutUs;
