import React, {useContext} from "react";
import {makeStyles} from "@mui/styles";
import {AuthContext} from "../contexts/Auth";
import Header from "./Header";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {

  },
  productBox: {
    marginBottom: '20px',
    padding: '20px',
    border: '1px solid #ccc',
  }
}))


function Admin() {
  const classes = useStyles();
  const {user} = useContext(AuthContext);

  if (!user) {
    return (
      <Redirect to="/login" />
    );
  }

  let userGroups = [];

  console.log(user)

  if (user && user.username) {
    userGroups = user?.signInUserSession?.idToken?.payload['cognito:groups'] || [];
    console.log('userGroups', userGroups)
  }

  return (
    <div className={classes.root}>
      <Header />

      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12}>
            <h1>
              Products
            </h1>
            {userGroups && userGroups.map(groupName => {
              return (
                <div className={classes.productBox}>
                  <h2>{groupName}</h2>
                </div>
              )
            })}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Admin;
