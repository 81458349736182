import Amplify, { API, Auth } from "aws-amplify";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import theme from './theme';
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";

const awsConfig = {
  "aws_cognito_region": "eu-west-2",
  "aws_user_pools_id": process.env.REACT_APP_COGNITO_USER_POOL_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
}

Amplify.configure(awsConfig);
API.configure(awsConfig);
Auth.configure(awsConfig);

// console.log('REACT_APP_API_ENDPOINT', process.env.REACT_APP_API_ENDPOINT)
// console.log('REACT_APP_ADMIN_PANEL_ENDPOINT', process.env.REACT_APP_ADMIN_PANEL_ENDPOINT)
// console.log('REACT_APP_COGNITO_USER_POOL_ID', process.env.REACT_APP_COGNITO_USER_POOL_ID)
// console.log('REACT_APP_COGNITO_USER_POOL_ARN', process.env.REACT_APP_COGNITO_USER_POOL_ARN)
// console.log('REACT_APP_COGNITO_USER_POOL_CLIENT_ID', process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID)

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
