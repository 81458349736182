import {API, Auth} from "aws-amplify";
import { HttpMethod, HttpMethods } from "./Types.crm";
import axios from 'axios'

export default class MarketplaceService {
  #url = () => {
    const endpoint = process.env?.REACT_APP_API_ENDPOINT;

    if (!endpoint) {
      throw new Error('Marketplace API url not configured');
    }

    if (!endpoint.startsWith('https://')) {
      throw new Error('Contact CRM URL must use SSL transport security');
    }

    return endpoint;
  };

  #idToken = async () => {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().getJwtToken();
  };

  #defaultHeaders = async () => ({
    'Accept': 'application/json',
    "Authorization": await this.#idToken()
  });

  #getHeaders = async (method: HttpMethod, body?: string): Promise<{ [key: string]: any; }> => {
    const additionalHeaders: { [key: string]: any; } = {};

    switch (method) {
      case HttpMethods.Put:
      case HttpMethods.Post:
      case HttpMethods.Delete: {
        if (body) {
          additionalHeaders['Content-Length'] = body.length.toString();
          additionalHeaders['Content-Type'] = 'application/json';
        }
        break;
      }
      default: break;
    }

    return Object.assign({}, await this.#defaultHeaders(), additionalHeaders);
  };

  // async saveCustomer(queryParams, formData) {
  //   return await API.post('client', '/save', {
  //     queryStringParameters: {
  //       customerId: queryParams['customerId'],
  //       productCode: queryParams['productCode'],
  //       registrationToken: queryParams['registrationToken'],
  //     },
  //     headers: {},
  //     body: {
  //       ...formData,
  //       attributes: {
  //         ...formData.attributes,
  //       }
  //     },
  //   })
  // }

  // async getCampaigns() {
  //   const params = {
  //     method: HttpMethods.Get,
  //     headers: await this.#getHeaders(HttpMethods.Get),
  //   };
  //
  //   const url = `${this.#url()}/ad/campaigns`;
  //   const response = await fetch(url, params);
  //
  //   if (!response.ok) {
  //     console.error('Failed to get campaigns', response);
  //   }
  //
  //   return response;
  // }
  //
  // async getCampaign(id: string) {
  //   const params = {
  //     method: HttpMethods.Get,
  //     headers: await this.#getHeaders(HttpMethods.Get),
  //   };
  //
  //   const url = `${this.#url()}/ad/campaign/${id}`;
  //   const response = await fetch(url, params);
  //
  //   if (!response.ok) {
  //     console.error(`Failed to get campaign ${id}`, response);
  //   }
  //
  //   return response;
  // }
  //
  // async deleteCampaign(id: string) {
  //   const params = {
  //     method: HttpMethods.Delete,
  //     headers: await this.#getHeaders(HttpMethods.Delete),
  //   };
  //
  //   const url = `${this.#url()}/ad/campaign/${id}`;
  //   const response = await fetch(url, params);
  //
  //   if (!response.ok) {
  //     console.error('Failed to delete campaign', response);
  //   }
  //
  //   return response;
  // }
  //
  // async createCampaign(body: string) {
  //   const params = {
  //     method: HttpMethods.Post,
  //     headers: await this.#getHeaders(HttpMethods.Post, body),
  //     body
  //   };
  //
  //   const url = `${this.#url()}/ad/campaign`;
  //   const response = await fetch(url, params);
  //
  //   if (!response.ok) {
  //     console.error('Failed to create campaign', response);
  //   }
  //
  //   return response;
  // }
  //
  // async updateCampaign(id: string, body: string) {
  //   const params = {
  //     method: HttpMethods.Put,
  //     headers: await this.#getHeaders(HttpMethods.Put, body),
  //     body
  //   };
  //
  //   const url = `${this.#url()}/ad/campaign/${id}`;
  //   const response = await fetch(url, params);
  //
  //   if (!response.ok || response.status !== 200) {
  //     console.error('Failed to update campaign', response);
  //     throw new Error('Failed')
  //   }
  //
  //   return response;
  // }
  //
  // async getCampaignUploads(id: string) {
  //   const params = {
  //     method: HttpMethods.Get,
  //     headers: await this.#getHeaders(HttpMethods.Get),
  //   };
  //
  //   const url = `${this.#url()}/ad/campaign/${id}/uploads`;
  //   const response = await fetch(url, params);
  //
  //   if (!response.ok) {
  //     console.error('Failed to get campaign uploads', response);
  //   }
  //
  //   return response;
  // }
  //
  // async getCampaignCustomersDownloadFile(campaignId: string) {
  //   const params = {
  //     method: HttpMethods.Get,
  //     headers: {
  //       ...await this.#getHeaders(HttpMethods.Get),
  //       'Accept': 'text/csv',
  //     },
  //   };
  //
  //   const url = `${this.#url()}/ad/campaign/${campaignId}/customers?download=true`;
  //   const response = await fetch(url, params);
  //
  //   if (!response.ok) {
  //     console.error('Failed to get campaign customers download file', response);
  //   }
  //
  //   return response;
  // }
  //
  // async getCampaignMetrics(campaignId: string) {
  //   const url = `${this.#url()}/ad/metrics/campaign/${campaignId}`;
  //   const config = {
  //     headers: await this.#getHeaders(HttpMethods.Get)
  //   }
  //   return await axios.get(url, config)
  // }
}
