import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, "Droid Sans", "Helvetica Neue", Helvetica, sans-serif'
  },
  palette: {
      primary: {
          main: '#fe418d',
      },
      secondary: {
          main: '#46ddcf',
      },
  },
  shape: {
    borderRadius: 0,
  },
  shadows: ["none"],
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: '#fe418d',
          backgroundColor: "#f5f3f4"
        }
      }
    }
  }
});

export default theme;
